import React from 'react';

const useStyleContainer = (props) => {

  const getStyleConfig = () => {
    return {
      containerPanel: {
          height: `calc(100vh - ${props.minusHeight})`
          ,width: '100%'
          ,display: 'flex'
          ,flexDirection: props.flexDirection
          ,alignItems: props.alignItems
          ,overflowY: props.overflowY
          ,overflowX: props.overflowX
          ,justifyContent: props.justifyContent
          ,backgroundColor: props.backgroundColor
          ,margin: '0px !important'
          ,padding: '0px !important'
      }
      ,containerApply: props.sx
    }
  }

  const [style, setStyle] = React.useState(getStyleConfig());

  React.useEffect(()=>{
    setStyle(getStyleConfig());
  },[props]);

  return style;

}

useStyleContainer.defaultProps = {
  flexDirection: 'row'
  ,alignItems: 'flex-start'
  ,overflowY: 'hidden'
  ,overflowX: 'hidden'
  ,justifyContent: 'flex-start'
  ,backgroundColor: 'inherit'
  ,sx: {}
}

export default useStyleContainer;
