import React from 'react';
import useStyleContainer from './styles/useStyleContainer';
import {createUseStyles} from 'react-jss';

export default function ContainerPanel(props) {

    const useStyles = createUseStyles(useStyleContainer(props));
    const classes = useStyles();

    return (!classes ? '' :
        <div className={`${classes.containerPanel} ${classes.containerApply} ${props.className}`}>
            {props.children ?? props.html}
        </div>
    )
}

ContainerPanel.defaultProps = {
    minusHeight : '0px' //58px
    ,flexDirection: 'column'
    ,alignItems: 'flex-start'
    ,overflowY: 'auto'
    ,overflowX: 'hidden'
    ,justifyContent: 'flex-start'
    ,backgroundColor: 'inherit'
    ,sx: {}
    ,className: ''
    ,html: null
}
